<template>
  <div class="login">
    <img class="card-anim" src="/img/home/outer_card_anim.gif" alt="" />
    <div class="login-wrap">
      <div class="abst-top"></div>
      <div class="abst-bottom"></div>
      <div class="logo"></div>
      <div class="inputs">
        <label for="user">Username</label>
        <input id="user" type="text" name="user" />
        <label for="pass">Password</label>
        <input id="pass" type="password" name="pass" />
      </div>
      <div class="btns">
        <button class="red">LOGIN</button>
        <p>OR</p>
        <button class="blue">REGISTER</button>
      </div>
    </div>
    <!-- <div class="video-wrap">
      <div class="play">▶</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Login",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.login {
  position: relative;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
}
label {
  text-decoration: underline;
  margin-bottom: 5px;
  z-index: 4;
}
.card-anim {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  z-index: 0;
}
.inputs {
  width: 100%;
  z-index: 4;

}
.logo {
  width: 70%;
  height: 47%;
  background: url("/img/home/Logo_Art_Wars.png") center;
  border: none;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: 100%;
  background-position: 50% 50%;
  position: absolute;
  top: -100px;
  left: -80px;
}

.login-wrap {
  // position: relative;
  display: flex;
  flex-direction: column;
  width: 30%;
  min-height: 400px;
  min-width: 250px;
  padding: 50px;
  padding-top: 90px;
  z-index: 2;
  background: url("/img/home/card-abstract.png") no-repeat,
    url("/img/home/card-abstract-bottom.png") no-repeat;
  background-color: black;
  border: none;
  background-size: 100%, 170%;
  background-position: 120px -20%, -200px 160%;
  border: white 10px solid;
  input {
    margin-bottom: 40px;
    line-height: 40px;
    padding-left: 20px;
  }
  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
    font-family: "Audiowide", cursive;

      color: white;
      width: 100%;
      line-height: 60px;
      border: none;
      font-size: 20px;
      margin: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      &.red {
        background: url("/img/home/red_btn.png") center no-repeat;
      }
      &.blue {
        background: url("/img/home/blue_btn.png") center no-repeat;
      }
    }
  }
}
.video-wrap {
  border: black 1px solid;
  height: 300px;
  width: 30%;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  .play {
    border-radius: 50%;
    // width: 60px;
    // height: 60px;
    // border: black 1px solid;
    font-size: 50px;
    text-align: center;
  }
}
</style>
